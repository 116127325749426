import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useQuery } from "react-query";
import {
    Typography,
    Paper,
} from "@mui/material";
import { getAnalyticsData } from "../../services/Dashboard";

const ExtendedAnalytics = ({ type, color }) => {
    const { data, isLoading: analyticsLoading } = useQuery(
        ["getAnalyticsData", type],
        getAnalyticsData,
        {
            refetchOnWindowFocus: false,
        }
    );

    const CustomTooltip = ({ point: { data: { x, y } = {} } = {} }) => {
        return (
            <Paper
                style={{
                    padding: "6px",
                }}
            >
                <div>
                    <Typography
                        color="text.primary"
                        sx={{ fontSize: 16, textAlign: "left" }}
                    >
                        {x}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        sx={{ fontSize: 12, textAlign: "left" }}
                    >{` ${y} ${type}${y > 1 ? "s" : ""}`}</Typography>
                </div>
            </Paper>
        );
    };

    return data && !analyticsLoading ?
        <ResponsiveLine
            tooltip={CustomTooltip}
            colors={[color]}
            data={[data]}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Months",
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        /> : null;
}

export default ExtendedAnalytics;
