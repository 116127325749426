import React, { useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getVehicleGroups,
  createGroup,
  updateVehiclesInGroup,
} from "../../../services/vehicle";
import {
  getVehicleData,
  getVehiclesInGroup,
} from "../../../services/deployment";
import { useToast } from "../../toast";
// import SwitchAction from "./SwitchActions";
import GroupNameInput from "./GroupNameInput";
import GroupDescription from "./GroupDescription";
import VehiclesTransferList from "./VehiclesTransferList";
import { Delete } from "@mui/icons-material";
import { getColumnsData } from "../../vehicle/VehicleColumns";
import CloseIcon from "@mui/icons-material/Close";

const CreateVehicleGroup = (props) => {
  const defaultFilters = [
    {
      value: true,
      operatorValue: "equals",
      columnField: "active",
    },
  ];
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { open, toggleDialog } = props;
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(!!open?.vehicleGroupId);
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(
    !!open?.vehicleGroupId ? open : null
  );
  const [vehicleGroupName, setVehicleGroupName] = useState(
    open?.vehicleGroupName || ""
  );
  const [vehicleGroupId, setVehicleGroupId] = useState(
    open?.vehicleGroupId || ""
  );
  const [groupDesc, setGroupDesc] = useState(
    !!open?.vehicleGroupId ? open?.description : ""
  );
  const [removedVehicles, setRemovedVehicles] = useState();
  const [selectedVehicles, setSelectedVehicles] = useState(
    open?.createGroupFromSelectedItems ? open?.items : []
  );
  const [primaryPage, setPrimaryPage] = useState(1);
  const [secondaryPage, setSecondaryPage] = useState(1);
  const [filterObject, setFilterObject] = useState({
    value: "",
    operatorValue: "contains",
    columnField: "serialNo",
  });
  const [primaryPaginationData, setPrimaryPaginationData] = useState({
    page: 1,
  });
  const [secondaryPaginationData, setSecondaryPaginationData] = useState({
    page: 1,
  });

  const [secondarySearchString, setSecondarySearchString] = useState("");

  const columns = getColumnsData();

  const { data: vehicleGroups = [] } = useQuery(
    "vehicleGroup",
    getVehicleGroups,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading: vehicleListLoading } = useQuery(
    [
      "vehicles",
      primaryPage,
      50, // pageSize
      "serialNoCreatedAt", //field
      "DESC", // sort
      {}, //filterValue,
      columns, //columns,
      [...defaultFilters, filterObject],
    ],
    getVehicleData,
    {
      onSuccess: (response) => {
        const { data: { meta = {} } = {} } = response;
        setPrimaryPaginationData({
          rowCount: meta.itemCount,
          count: meta.pageCount,
          page: meta.page,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const vehiclesList = data?.data?.res || [];
  const vehiclesListMeta = data?.data?.meta || {};

  const { data: vehiclesData, isLoading: vehicleInGroupListLoading } = useQuery(
    [
      "vehicles",
      secondaryPage,
      50, // pageSize
      "serialNoCreatedAt", //field
      "DESC", // sort
      open?.vehicleGroupId, //filterValue,
      secondarySearchString,
      true,
    ],
    getVehiclesInGroup,
    {
      enabled: !!open?.vehicleGroupId,
      onSuccess: (response) => {
        const { data: { res, meta = {} } = {} } = response;
        // setSelectedVehicles(res);
        setSecondaryPaginationData({
          rowCount: meta.itemCount,
          count: Math.ceil(meta.itemCount / 50),
          page: meta.page,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const vehiclesInGroupList = vehiclesData?.data?.res || [];
  const vehiclesInGroupListMeta = vehiclesData?.data?.meta || {};

  const { mutate, isLoading } = useMutation(createGroup, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries("vehicleGroup");
      queryClient.invalidateQueries("vehicleGroupsList");
      queryClient.invalidateQueries("getStatsForVehicleGroupsView");
    },
  });

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(
    updateVehiclesInGroup,
    {
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries("vehicleGroup");
        queryClient.invalidateQueries("vehicleGroupsList");
        queryClient.invalidateQueries("getStatsForVehicleGroupsView");
      },
    }
  );

  const handleCreate = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    mutate(
      {
        vehicleGroupName: vehicleGroupName,
        description: groupDesc,
        vehicleKeys: selectedVehicles.map((i) => i.vehicleKey),
      },
      {
        onSuccess: (group) => {
          toggleDialog();
          addToast({
            type: "success",
            message: `Group ${group?.data?.vehicleGroupName} created successfully.`,
            autoClose: 3000,
          });
        },
        onError: (e) => {
          const { response } = e;
          addToast({
            type: "error",
            message: response?.data?.message || "Failed to create",
            autoClose: 3000,
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  const handleUpdate = () => {
    updateMutation(
      {
        vehicleGroupId: vehicleGroupId,
        vehicleGroupName: vehicleGroupName,
        description: groupDesc,
        vehicleKeys: {
          addVehicles: selectedVehicles?.map((i) => i.vehicleKey),
          removeVehicles: removedVehicles?.map((i) => i.vehicleKey),
        },
      },
      {
        onSuccess: (group) => {
          toggleDialog();
          addToast({
            type: "success",
            message: `Group ${group?.data?.vehicleGroupName} updated successfully.`,
            autoClose: 3000,
          });
        },
        onError: (e) => {
          const { response } = e;
          addToast({
            type: "error",
            message: response?.data?.message || "Failed to update",
            autoClose: 3000,
          });
        },
      }
    );
  };

  const handlePrimaryPageChange = (event, pageNumber) => {
    console.log(event, pageNumber);
    setPrimaryPage(pageNumber);
  };
  const handleSecondaryPageChange = (event, pageNumber) => {
    console.log(event, pageNumber);
    setSecondaryPage(pageNumber);
  };

  const onSearchInputPrimary = (value) => {
    if (!!value) {
      setFilterObject({
        value: value,
        operatorValue: "contains",
        columnField: "serialNo",
      });
    } else {
      setFilterObject({
        value: "",
        operatorValue: "contains",
        columnField: "serialNo",
      });
    }
    setPrimaryPage(1);
  };

  const onSearchInputSecondary = (value) => {
    if (!!value) {
      setSecondarySearchString(value);
    } else {
      setSecondarySearchString("");
    }
    setSecondaryPage(1);
  };

  const onSwitchChange = () => {
    setIsUpdate(!isUpdate);
    setVehicleGroupName("");
    setSelectedVehicleGroup(null);
    setGroupDesc("");
  };

  const groupNameInputProps = {
    isUpdate,
    onSwitchChange,
    vehicleGroupName,
    setVehicleGroupName,
    vehicleGroups,
    selectedVehicleGroup,
    setSelectedVehicleGroup,
  };
  const groupNameDescProps = {
    isUpdate,
    onSwitchChange,
    groupDesc,
    setGroupDesc,
    selectedVehicleGroup,
  };

  const transferListProps = {
    isUpdate,
    vehicleGroups,
    vehiclesList,
    vehiclesListMeta,
    vehiclesInGroupList,
    vehiclesInGroupListMeta,
    selectedVehicles,
    setSelectedVehicles,
    removedVehicles,
    setRemovedVehicles,
    selectedVehicleGroup,
    setSelectedVehicleGroup,
    primaryPaginationData,
    setPrimaryPaginationData,
    secondaryPaginationData,
    setSecondaryPaginationData,
    onSearchInputPrimary,
    onSearchInputSecondary,
    handlePrimaryPageChange,
    handleSecondaryPageChange,
  };

  return (
    <Dialog
      open={open}
      onClose={toggleDialog}
      PaperProps={{
        sx: { width: "50%", maxWidth: "1200px", height: "calc(100vh - 30px)" },
      }}
    >
      <DialogTitle>
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <div>Vehicle group</div> */}
          <Typography variant="h6" gutterBottom>
            {isUpdate ? "Edit Vehicle Group" : "Create Vehicle Group"}
          </Typography>
          <div style={{ marginLeft: "1em" }}>
            {" "}
            {/* <SwitchAction isUpdate={isUpdate} onChange={onSwitchChange} /> */}
          </div>
        </span>
        <IconButton
          aria-label="close"
          onClick={toggleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Tooltip title="Close create dialog">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <Grid container>
          <Grid item xs={12}>
            <GroupNameInput {...groupNameInputProps} />
          </Grid>
          <Grid item xs={12}>
            <GroupDescription {...groupNameDescProps} />
          </Grid>
          <Grid item xs={12}>
            <VehiclesTransferList {...transferListProps} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog}>Cancel</Button>
        {isUpdate ? (
          <Button
            onClick={handleUpdate}
            disabled={updateLoading || vehicleGroupName?.length < 1}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={handleCreate}
            disabled={loading || vehicleGroupName?.length < 1}
          >
            Create
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateVehicleGroup;
