import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Lottie from "react-lottie";
import deploymentLoadingAnim from "../../../lottieAnimations/deployment_loading.json";
import deploymentFailedAnim from "../../../lottieAnimations/deployment_failed.json";
import { WizardContext, ACTIONS, TABS } from "../Context";
import { createRelease } from "../../../services/Release";
import { createDeployment } from "../../../services/deployment";
import { getUserName } from "../../../services/Users";
import ReviewDetails from "./ReviewDetails";
import { useToast } from "../../toast";

const ReviewWizard = () => {
  const [successAnimation, setSuccessAnimation] = useState(false);
  const [failed, setFailed] = useState(false);
  const { state, dispatch } = useContext(WizardContext);
  const { addToast } = useToast();
  const {
    toggleVehicle,
    vehicles,
    vehicleGroups,
    toggleRelease,
    releaseName,
    release: { id: releaseId = "" },
    images,
    campaignEndDate,
    campaignStartDate,
    deploymentName,
    deploymentType,
    launch,
    deploymentMessage,
  } = state;
  const queryClient = useQueryClient();

  const getPayload = async (releaseId) => {
    return {
      deploymentName: deploymentName,
      deploymentType: deploymentType,
      releaseId,
      serialNo: vehicles,
      campaignStartDate: campaignStartDate,
      campaignEndDate: campaignEndDate,
      vehicleGroups: vehicleGroups?.map((i) => i.split("=")[0]),
    };
  };

  // Mutations
  const {
    mutate: deploymentMutate,
    isLoading: deploymentLoading,
    error: deploymentError,
  } = useMutation(createDeployment, {
    onSuccess: (data) => {
      setSuccessAnimation(true);
      const { data: { deploymentId = "" } = {} } = data || {};
      addToast({
        type: "success",
        message: "Deployment Created",
        autoClose: 5000,
      });
      dispatch({
        type: ACTIONS.TOGGLE_BUTTON,
        payload: {
          deploymentId: deploymentId,
          disableNextBtn: false,
        },
      });
      queryClient.invalidateQueries("deployments");
    },
    onError: (err) => {
      addToast({
        type: "error",
        message: err.response.data.message || "Failed to create deployment",
        autoClose: 5000,
      });
      setFailed(true);
      dispatch({
        type: ACTIONS.TOGGLE_BUTTON,
        payload: {
          disableNextBtn: true,
          launch: false,
        },
      });
    },
  });

  const {
    mutate: mutateRelease,
    isLoading: releaseLoading,
    error: releaseError,
  } = useMutation(createRelease, {
    onSuccess: async (data) => {
      const { data: { releaseId = "" } = {} } = data;
      const payload = await getPayload(releaseId);
      deploymentMutate(payload);
      addToast({
        type: "success",
        message: "Release Created",
        autoClose: 5000,
      });
      dispatch({
        type: ACTIONS.TOGGLE_BUTTON,
        payload: {
          disableNextBtn: false,
        },
      });
    },
    onError: (err) => {
      addToast({
        type: "error",
        message: err.response.data.message || "Failed to create release",
        autoClose: 5000,
      });
      setFailed(true);
      dispatch({
        type: ACTIONS.TOGGLE_BUTTON,
        payload: {
          disableNextBtn: true,
          launch: false,
        },
      });
    },
  });

  let vehicleState = false,
    releaseState = false;
  if (toggleVehicle === TABS.CHOOSE_VEHICLES) {
    vehicleState = vehicles.length > 0;
  } else if (toggleVehicle === TABS.CHOOSE_VEHICLE_GROUPS) {
    vehicleState = vehicleGroups.length > 0;
  }

  if (toggleRelease === TABS.CREATE_RELEASE) {
    releaseState = releaseName && images.length > 0;
  } else if (toggleRelease === TABS.CHOOSE_RELEASE) {
    releaseState = releaseId !== "";
  }

  releaseState = releaseError ? false : releaseState;

  const handleSubmission = async () => {
    if (vehicleState && releaseState) {
      dispatch({
        type: ACTIONS.TOGGLE_BUTTON,
        payload: {
          disableNextBtn: true,
        },
      });
      if (toggleRelease === TABS.CREATE_RELEASE) {
        mutateRelease({
          releaseName: releaseName,
          imageIds: images,
          createdBy: await getUserName(),
        });
      } else if (toggleRelease === TABS.CHOOSE_RELEASE) {
        const payload = await getPayload(releaseId);
        deploymentMutate(payload);
      }
    }
  };

  useEffect(() => {
    if (launch) {
      handleSubmission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launch]);

  if (
    releaseLoading ||
    deploymentLoading ||
    successAnimation ||
    deploymentError ||
    releaseError ||
    deploymentMessage !== ""
  ) {
    let infoText = "Creating deployment...";
    if (successAnimation) {
      infoText = "Deployment created";
    }
    if (deploymentError || releaseError || failed) {
      infoText = "Deployment failed, try again later.";
    }
    if (deploymentMessage) {
      infoText = deploymentMessage;
    }
    if (failed) {
      return (
        <>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: deploymentFailedAnim,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={300}
            width={300}
          />
          <p className="wizard-loading-text">{infoText}</p>
        </>
      );
    }
    return (
      <>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: deploymentLoadingAnim,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={300}
          width={300}
        />
        <p className="wizard-loading-text">{infoText}</p>
      </>
    );
  }

  return <ReviewDetails />;
};

export default ReviewWizard;
